(fn => document.readyState != 'loading' ? fn() : document.addEventListener('DOMContentLoaded', fn))(() => {

  // document.querySelectorAll('input[name="category"]').forEach(node => node.addEventListener('change', (e) => {}));

  const clampNum=(c,a,l)=>c<a?a:c>l?l:c;

  function lerp(v0, v1, a) {
    return (1-a)*v0+a*v1;
  }

  function debounce(func, delay = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, delay);
    };
  }

  let parallaxContainer = document.querySelector('div.parallax');
  let parallaxGroupHeader = document.querySelector('div.parallax-group.-header');
  let logo = document.querySelector('div.logo');
  let canvas = document.querySelector('canvas.background-static');
  let screenshots = document.querySelectorAll('li.screenshot');
  let videoLoop = document.querySelector('video.video-loop');

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
  let ctx = canvas.getContext('2d');

  function drawBackgroundStatic() {
    let w = 300;
    let h = 300;
    let imgData = new ImageData(w, h);
    let d = imgData.data;

    for(let i = 0; i < d.length; i += 4) {
      let r = Math.random()*256|0;
      d[i] = r; // r
      d[i + 1] = r; //g
      d[i + 2] = r; //b
      d[i + 3] = 26; //a
    }

    for(let y = 0; y < window.innerHeight; y += h) {
      for(let x = 0; x < window.innerWidth; x += w) {
        ctx.putImageData(imgData, x, y);
      }
    }
  }

  function loop(t) {
    drawBackgroundStatic();
    window.requestAnimationFrame(t => loop(t));
  }

  function handleScreenshotClick(node) {
    if(node.classList.contains('selected')) {
      screenshots.forEach(thisNode => {
          thisNode.classList.remove('selected','not-selected');
      });
    } else {
      screenshots.forEach(thisNode => {
        if(thisNode == node) {
          thisNode.classList.add('selected');
        } else {
          thisNode.classList.add('not-selected');
        }
      });
    }
  }
  
  window.addEventListener('resize', debounce(e => {
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  }, 100));
  
  parallaxContainer.addEventListener('scroll', e => {
    let groupHeight = parallaxGroupHeader.clientHeight;
    let ia = (parallaxContainer.scrollTop - (groupHeight - 80)) / (groupHeight - 80);
    let h = lerp(400, 210, clampNum(1+ia, 0, 1));
    logo.style.height = `${h}px`;
  });

  screenshots.forEach(node => {
    node.addEventListener('click', e => {
      handleScreenshotClick(node);
    });
  });

  videoLoop.addEventListener('ended', e => {
    videoLoop.load();
    videoLoop.play();
  });
  
  loop(0);

});